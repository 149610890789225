<template>
  <div>
    <b-card>
      <b-button-toolbar>
        <b-button-group>
          <b-button
            variant="primary"
            @click="onCreateDiscount"
          >
            Create discount
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card>
    <gql-table
      ref="discountsGqlTable"
      :fields="fields"
      :query="query"
      query-type="discounts"
      :sort-desc="true"
      :actions="actions"
      @detail="onDetail"
      @edit="onEditDiscount"
    >
      <template #cell(name)="data">
        <b-link :to="{name: 'discount-detail', params: {id: data.item.id}}">
          {{ data.item.name }}
        </b-link>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt ) }}
      </template>

      <template #cell(active)="data">
        <b-badge :variant="data.item.active ? 'light-primary' : 'light-danger'">
          {{ data.item.active ? 'active' : 'disabled' }}
        </b-badge>
      </template>

      <template #cell(registeredAfter)="data">
        <b-badge
          v-if="data.item.registeredAfter === null"
          variant="light-danger"
        >
          inactive option
        </b-badge>
        <span v-else>
          {{ formatDateTime(data.item.registeredAfter ) }}
        </span>
      </template>

      <template #cell(partner)="data">
        <span v-if="data.item.partner">{{ data.item.partner.aff_custom_key }} | {{ data.item.partner.user.email }}</span>
      </template>

    </gql-table>

    <b-modal
      id="discount-modal"
      :title="title"
      :no-close-on-backdrop="true"
      size="lg"
      hide-footer
    >
      <discount-form
        :discount="discountEdit"
        @submit="onEditSubmit"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BButton, BButtonGroup, BButtonToolbar, BCard, BLink, BModal,
} from 'bootstrap-vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import DiscountForm from '@/components/forms/discount/DiscountForm.vue'
import { formatDate, formatDateTime } from '@/plugins/formaters'

const fields = [
  {
    name: 'name',
    label: 'Name',
    filterable: 'like',
  },
  {
    name: 'value_string', label: 'Value',
  },
  {
    name: 'createdAt',
    label: 'Created at',
    sortable: true,
  },
  {
    name: 'discountCode',
    label: 'Discount code',
    filterable: 'like',
    visible: false,
  },
  {
    name: 'active',
    label: 'State',
    sortable: true,
    filterable: {
      type: 'is',
      labels: { true: 'active', false: 'inactive' },
    },
    default: true,
  },
  {
    name: 'registeredAfter',
    label: 'Registered After',
    sortable: true,
  },
  {
    name: 'partner',
    label: 'Partner',
  },
  {
    name: 'actions',
  },
]

const query = [
  'id',
  'name',
  'active',
  'value_string',
  'registeredAfter',
  'absolute_value',
  'relative_value',
  'createdAt',
  {
    partner: {
      fields: [
        'aff_custom_key',
        'id',
        {
          user: {
            fields: ['id', 'email'],
          },
        },
      ],
    },
  },
]

const actions = [
  {
    text: 'Discount details',
    icon: 'SearchIcon',
    emit: 'detail',
  },
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Change state',
    icon: 'Edit2Icon',
    emit: 'state',
  },
]

export default {
  components: {
    BBadge,
    BCard,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BModal,
    DiscountForm,
    BLink,
    GqlTable,
  },
  data() {
    return {
      fields,
      query,
      actions,
      discountEdit: null,
      title: '',
    }
  },
  methods: {
    formatDateTime,
    formatDate,
    onEditSubmit() {
      this.$bvModal.hide('discount-modal')
      this.$refs.discountsGqlTable.reloadAll(true)
    },
    onCreateDiscount() {
      this.title = 'Create discount'
      this.discountEdit = null
      this.$bvModal.show('discount-modal')
    },
    onEditDiscount(item) {
      this.title = 'Edit discount'
      this.discountEdit = item
      this.$bvModal.show('discount-modal')
    },
    onDetail(item) {
      this.$router.push({ name: 'discount-detail', params: { id: item.id } })
    },
  },
}
</script>
